import React from 'react'
import AboutImage from '../../assets/images/about/about-img8.png'
//import AboutShape from '../../assets/images/about/about-shape1.png'

const Content = () => {
    return (
        <section className="solutions-area">
            <div className="container pt-70 pb-30">
                <h2 className="subtitle center">Rendez accessibles vos procédures et documents sur des bornes tactiles !</h2>
                <div className='overview-box'>
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={AboutImage} alt="" />
                        </div>
                    </div>

                    <div className='overview-content'>
                        <div className='content'>
                            <h3>Quelle est la valeur ajoutée ?</h3>
                            <p>La digitalisation des processus, en respectant le savoir-faire, permet de <span className='bold'>simplifier votre système de management</span>.<br></br>
                            Disposez d’une diversité de fonctionnalités disponibles comme :</p>
                            <ul className='features-list'>
                                <li><span><i class="bx bx-check"></i> Lecture et gestion de document</span></li>
                                <li><span><i class="bx bx-check"></i> Formulaires numériques</span></li>
                                <li><span><i class="bx bx-check"></i> Prise de photo ou de vidéo</span></li>
                                <li><span><i class="bx bx-check"></i> et bien d'autres ...</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-30 pb-70">
                <p>Toutes ces ressources rassemblées sur un même outil se traduit au quotidien par un <span className='bold'>gain de temps, de réactivité et de performance</span>. 
                La borne est adaptée pour le terrain, la gestion multisite et le management décentralisé et offre à votre entreprise une <span className='bold'>navigation restreinte et sécurisée</span>.
                Grâce à notre <span className='bold'>générateur de QR Code intégré</span>, vous pouvez accéder facilement à tous vos documents (formulaires, procédures, etc.).</p>
                
                <div className="ptb-30" id="list-bornes">
                    <div className="col-lg-12 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bx-check'></i>
                            </div>
                            <p>
                                <span className='bold'>Remplacer le fichier papier</span> grâce à des formulaires numériques accessibles depuis la tablette. Contrairement au papier, les fichiers sont automatiquement transmis, partagés et sauvegardés en quelques clics.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-12 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i class='bx bx-check'></i>
                            </div>
                            <p>
                                <span className='bold'>Essentielle pour la communication à distance</span>, accédez et transmettez vos documents de partout et à l’ensemble de vos équipes. Cela vous permet d’interagir avec tous vos collaborateurs, notamment ceux qui n’ont pas accès à un ordinateur de travail en leur fournissant des informations utiles à votre activité.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-12 col-sm-6">
                        <div className="single-solutions-box ">
                            <div className="icon">
                                <i class='bx bx-check'></i>
                            </div>
                            <p>
                                <span className='bold'>Optimiser les retours d’expérience terrain :</span> que cela soit un évènement positif ou négatif, le formulaire digital disponible sur la borne permet de collecter, analyser et exploiter les données liées à votre démarche de prévention des risques et d’amélioration continue.
                            </p>
                        </div>
                    </div>
                </div>

                <h5 className='pt-30 bold'>Bornes et tablettes sont des équipements qui sont un support essentiel à la performance de votre activité et qui viennent en complément de vos autres outils de travail.</h5>  
            </div>
        </section>
    )
}

export default Content;