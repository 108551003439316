import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import PageBanner from '../components/Common/PageBanner'
import UnProjet from "../components/Common/UnProjet"
import ModBornes from '../components/Bornes/ModBornes'
import Content from "../components/Bornes/Content"
import ContentTwoCarousel from "../components/Bornes/ContentTwoCarousel"
import ContentThree from "../components/Bornes/ContentThree"
import { Helmet } from "react-helmet"

const Bornes = () => {
  return (
    <Layout>
      <Helmet>
        <title>Seekreet | Bornes & tablettes</title>
        <meta name="description" content="Solution complète avec des bornes, tablettes, smartphones pour optimiser la gestion de vos formulaires, audits. Fixe ou mobile équipez-vous de matériels de qualité, garantie 5 ans…" />
      </Helmet>
      <Navbar />
      <PageBanner
          pageTitle="Bornes" 
          homePageText="Accueil" 
          homePageUrl="/" 
          activePageText="Bornes" 
      />
      <ModBornes />
      <Content />
      <ContentTwoCarousel />
      <ContentThree />
      <UnProjet />
      <Footer />
    </Layout>
  )
}

export default Bornes