import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import borneFixe from '../../assets/images/borne_fixe.png'
import borneMob from '../../assets/images/borne_mobile.png'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ]
};

const ContentTwoCarousel = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="case-study-area bg-e1e7dd">
            {display ? <OwlCarousel 
                className="case-study-slides owl-carousel owl-theme"
                {...options}
            > 
                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="case-study" /> 
                                        Pour quel type d'utilisation
                                    </span>
                                    <h2>#1 Borne fixe</h2>
                                    <p>Une borne fixe est accessible à l’ensemble de vos équipes, vos visiteurs, clients, fournisseurs et autres tiers : salle de production, laboratoire, entrepôts, accueil, salle de repos.</p>
                                    <p>Voici plusieurs exemples d’utilisation :</p>
                                    <ul>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i> <span className='bold'>Un opérateur terrain :</span> peut utiliser la borne pour accéder à une bibliothèque de procédures à suivre pour le bon fonctionnement d’un appareil. Il peut également utiliser la tablette pour remplir une fiche de non-conformité et l’envoyer directement à son responsable.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i> <span className='bold'>Un salarié sans adresse mail professionnelle :</span> peut accéder à une borne pour remplir tous types de formulaires : demandes de congés ; demandes en formation, un questionnaire interne, mais également avoir accès aux documents obligatoires de l’entreprise.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i> <span className='bold'>Un visiteur (client, fournisseur, etc.) :</span> peut remplir un formulaire d’enregistrement des visites, accéder à des consignes de sécurité, ou encore valider un badge QR-code.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-12">
                                <div className="case-study-image">
                                    <span className="d-block">
                                        <img src={borneFixe} alt="case-study" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-case-study-item ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-12">
                                <div className="case-study-content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="case-study" /> 
                                        Pour quel type d'utilisation
                                    </span>
                                    <h2>#2 Borne mobile</h2>
                                    <p>Tablettes ou smartphones, ils sont adaptés à votre métier. L’outil est aussi bien accessible sur site qu’à l’extérieur sur des chantiers, lors d’une intervention chez un client, fixé dans un véhicule, etc.</p>
                                    <ul>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i> <span className='bold'>Un responsable QHSE :</span> peut se rendre sur un site pour effectuer un état des lieux d’une situation. Il peut consulter ses procédures, remplir ses formulaires de contrôle ou d’audit, prendre des photos ou des vidéos, le tout sur un seul outil.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i> <span className='bold'>Un poseur :</span> se rend chez un client et peut remplir sa fiche d’intervention, faire signer le document. Il peut également remplir une fiche de non-conformité et avertir directement son entreprise d’un dysfonctionnement.</li>
                                        <li className='ptb-10'><i class='bx bx-right-arrow-alt' ></i> <span className='bold'>Un salarié itinérant ou délocalisé (chauffeur, commercial, etc.)</span> qui est rarement sur site peut rester en contact avec son entreprise et accéder à distance aux documents obligatoires, à des procédures métiers ou bien signaler une situation à risque.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-5 col-md-12">
                                <div className="case-study-image">
                                    <span className="d-block">
                                        <img src={borneMob} alt="case-study" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </div>
    )
}

export default ContentTwoCarousel