import React from 'react'
import accessoire from '../../assets/images/accessoire-tab.png'

const ContentThree = () => {
    return (
        <section className="process-area ptb-70">
            <div className="container">
                <div className="section-title">
                    <h2>Un équipement professionnel adapté à vos spécificités métier</h2>
                </div>

                <div className='ptb-30'>
                    <h3>Caractéristiques :</h3>
                    <p>Revendeur de grandes marques professionnelles adaptées à <span className='bold'>différents corps de métiers et à des conditions difficiles</span> comme un entrepôt, des chantiers ou un lieu de fabrication contraints à la poussière, la pluie et le froid extrême.<br></br>
                    Notre équipement dispose d’un <span className='bold'>indice de protection IP 68</span>, une résistance aux chutes et aux vibrations, une longévité de la batterie et des capacités de capture de données.</p>
                </div>


                <div className='overview-box'>
                    <div className='overview-content'>
                        <div className='content'>
                            <p>Nous proposons également des <span className='bold'>accessoires</span> adaptés à différents besoins d’utilisation :</p>
                            <ul className='features-list'>
                                <li><span><i class="bx bx-check"></i> Coques de protection</span></li>
                                <li><span><i class="bx bx-check"></i> Films protecteurs</span></li>
                                <li><span><i class="bx bx-check"></i> Stylets</span></li>
                                <li><span><i class="bx bx-check"></i> Antivols</span></li>
                                <li><span><i class="bx bx-check"></i> Supports de fixation (muraux, pour véhicules, etc.)</span></li>
                                <li><span><i class="bx bx-check"></i> Housses et étuis</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={accessoire} alt="support tablette en bandoulière" />
                        </div>
                    </div>
                </div>

                <h4>La tablette répond à des usages spécifiques pour des fonctions métier fixes, mobiles ou en relation avec des tiers.</h4>
            </div>
        </section>
    )
}

export default ContentThree