import React from 'react';
import {Link} from 'gatsby'
//import AboutImage from '../../assets/images/about/about-img10.png'
import AboutShape from '../../assets/images/about/about-shape1.png'
import CircleShape from '../../assets/images/shape/circle-shape1.png'
import borne from '../../assets/images/home/borne.png'

const ModBornes = () => {
    return (
        <section className="about-area ptb-70 bg-e1e7dd">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={borne} alt="Borne Wispeek" />
                            <div className="shape">
                                <img src={AboutShape} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <h2>Tablette mobile, borne fixe<br></br>
                                Déployez des équipements numériques de travail !</h2>
                                <p>Borne, tablette, smartphone, ce sont les nouveaux outils indispensables à votre activité.<br></br>
                                La <span className='bold'>digitalisation des entreprises</span> se traduit par l’automatisation des tâches avec le développement d’applications métier.<br></br>
                                L’objectif pour l’entreprise est de mettre en place des outils efficaces adaptés aux <span className='bold'>nouvelles organisations et conditions de travail</span>.<br></br>
                                C’est un moyen d’augmenter la productivité et la fidélité des collaborateurs, en véhiculant une <span className='bold'>image professionnelle moderne et innovante</span>.</p>
                               
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-right"></i> 
                                    Demandez un conseil 
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={CircleShape} alt="Circle Shape" />
            </div>
        </section>
    )
}

export default ModBornes;